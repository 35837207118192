<template lang="pug">
  b-card.manager-organizations-roles-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-form.form-wc.form-organizations-role-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-roles-admin_accounts(
              :label="$t('role.codes.admin_scp')"
              label-for='roles-admin_account_ids'
              label-cols-md='2'
              label-align-md='right')
              multiselect#roles-admin_account_ids.form-input-first.shadow-sm.custom-select(
                name='admin_accounts'
                v-model='rolesAdminAccountIdsSelected'
                :multiple='true'
                :options='form.selects.rolesAdminAccountIdsOptions'
                :close-on-select='true'
                :clear-on-select='true'
                :placeholder="$t('role.placeholders.accounts')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.rolesAdminAccountIdsLoading'
                @search-change='searchAccounts'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}

            b-form-group#form-roles-manager_accounts(
              :label="$t('role.codes.manager_scp')"
              label-for='roles-manager_account_ids'
              label-cols-md='2'
              label-align-md='right')
              multiselect#roles-manager_account_ids.form-input-middle.shadow-sm.custom-select(
                name='manager_accounts'
                v-model='rolesManagerAccountIdsSelected'
                :multiple='true'
                :options='form.selects.rolesManagerAccountIdsOptions'
                :close-on-select='true'
                :clear-on-select='true'
                :placeholder="$t('role.placeholders.accounts')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.rolesManagerAccountIdsLoading'
                @search-change='searchAccounts'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}

            b-form-group#form-roles-editor_accounts(
              :label="$t('role.codes.editor_scp')"
              label-for='roles-editor_account_ids'
              label-cols-md='2'
              label-align-md='right')
              multiselect#roles-editor_account_ids.form-input-last.shadow-sm.custom-select(
                name='editor_accounts'
                v-model='rolesEditorAccountIdsSelected'
                :multiple='true'
                :options='form.selects.rolesEditorAccountIdsOptions'
                :close-on-select='true'
                :clear-on-select='true'
                :placeholder="$t('role.placeholders.accounts')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.rolesEditorAccountIdsLoading'
                @search-change='searchAccounts'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-roles-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/organizations/shared/tabs'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiRoles from '@services/api/manager/organizations/roles'
import { Form, mapSelects } from '@common/form'

export default {
  name: 'manager-organizations-roles-form',
  mixins: [Tabs, Form],
  components: {
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'rolesAdminAccountIds',
        default: 'relationships.admin_accounts.data',
        attribute: 'attributes.admin_account_ids',
        included: { type: 'account', key: 'id', value: 'attributes.id_humanized' },
        multiselect: { key: 'id' },
      },
      {
        name: 'rolesManagerAccountIds',
        default: 'relationships.manager_accounts.data',
        attribute: 'attributes.manager_account_ids',
        included: { type: 'account', key: 'id', value: 'attributes.id_humanized' },
        multiselect: { key: 'id' },
      },
      {
        name: 'rolesEditorAccountIds',
        default: 'relationships.editor_accounts.data',
        attribute: 'attributes.editor_account_ids',
        included: { type: 'account', key: 'id', value: 'attributes.id_humanized' },
        multiselect: { key: 'id' },
      },
    ]),
    apiParams() {
      return {
        get: [{ organization_id: this.organization_id }],
        update: [
          { organization_id: this.organization_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        rolesAdminAccountIdsSearch: [
          { organization_id: this.organization_id },
          { term: this.form.selects.rolesAdminAccountIdsTerm },
        ],
        rolesManagerAccountIdsSearch: [
          { organization_id: this.organization_id },
          { term: this.form.selects.rolesManagerAccountIdsTerm },
        ],
        rolesEditorAccountIdsSearch: [
          { organization_id: this.organization_id },
          { term: this.form.selects.rolesEditorAccountIdsTerm },
        ],
      }
    },
  },
  methods: {
    searchAccounts(term, id) {
      this.formMultiselectSearch(term, id, 'searchAccounts', 'id_humanized')
    },
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('roles-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            admin_account_ids: {},
            manager_account_ids: {},
            editor_account_ids: {},
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiRoles,
      apiModel: 'roles',
      organization_id: this.$route.params.organization_id,
      asyncDataSelects: ['rolesAdminAccountIds', 'rolesManagerAccountIds', 'rolesEditorAccountIds'],
    }
  },
}
</script>
